import React from "react";
import useTitleContext from "../../context/useTitleContext";
import BackBtn from "../../components/BackBtn/BackBtn";

function Datenschutz() {
  useTitleContext("Datenschutz");
  return (
    <>
      <BackBtn></BackBtn>
      <div className="container">
        <h2>Datenschutz</h2>
        <p>
          Wir nehmen den Schutz Ihrer persönlichen Daten ernst und behandeln
          Ihre personenbezogenen Daten vertraulich und entsprechend der
          gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Die Nutzung unserer Website ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h4>Cookies</h4>
        <p>
          Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Sie dienen dazu, Webangebote nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert.
        </p>
        <p>
          Die von uns verwendeten Cookies sind ausschließlich so genannte
          »Session-Cookies«. Sie werden nach Ende Ihres Besuchs (beim Schließen
          des Browsers) automatisch gelöscht. Diese Cookies ermöglichen es uns,
          Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren
          Browser so einstellen, dass Sie über das Setzen von Cookies informiert
          werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
          für bestimmte Fälle oder generell ausschließen sowie das automatische
          Löschen aller Cookies beim Schließen des Browser aktivieren. Bei der
          Deaktivierung von Cookies kann die Funktionalität dieser Website
          eingeschränkt sein.
        </p>
        <h4>Server-Log-Files</h4>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Files, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>
          Diese Daten können nicht bestimmten Personen zugeordnet werden. Eine
          Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen,
          wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
          werden.
        </p>
        <p>
          Quelle: <a href="http://www.erecht24.de/">eRecht24</a>
        </p>
      </div>
    </>
  );
}

export default Datenschutz;
